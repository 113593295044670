@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

html {
  --scrollbarBG: rgb(206, 206, 206);
  --thumbBG: #073c7c;
  --Primary: #2400FF;
  --Active : #29a300;
  --InActive :#d70000;
  --Black: #000000;
  --White: #FFFFFF;
}

body{
  font-family: 'Poppins', sans-serif !important;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  scroll-behavior: smooth;
  /* background: #ffffff !important; */
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}




@font-face {
  font-family: 'UniSans';
  src: url('./Assets/UniSans-Trial-Heavy.woff2') format('woff2');
  }

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}

a {
  text-decoration: none !important;
}

#primaryBtn{
  background: linear-gradient(145deg, rgb(0, 127, 232), #2400FF);
  color:var(--White);
  border: none;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

label.form-label{
  color: #575757 !important;
  font-size: 14px !important;
  padding-left: 2px;
  margin-bottom:4px !important;
  font-weight: 600 !important;
  display: flex !important;
  align-items: center !important;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
 }

 .viewBtn{
  background: transparent !important;
  color: var(--Primary) !important;
  border: 1px solid var(--Primary) !important;
  border-radius: 10px !important;
 }

 .viewBtn:hover{
  background: linear-gradient(145deg, rgb(0, 127, 232), #2400FF) !important;
  color: var(--White) !important;
 }


/* -------------------- Skeleton Loader CSS ------------------------- */
.loader {
  background-color: #ededed !important;
  height: 35px;
  border-radius: 2px;
  width: 100%;
}

.tr {
  border-bottom: 1px solid #ddd;
}

.td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

td .loader {
  background-color: #ededed;
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #ededed;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}

@keyframes loading {
  to {
    background-position-x: -40%;
  } 
}


.chartCard {
  text-align: center !important;
  padding: 20px 20px 35px 20px !important;
  border: 2px solid #aad7ff !important;
  border-radius: 12px !important;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important; */
}
/* CSS for print */
@media print {
  .a4-page {
    width: 210mm; /* A4 width */
    height: 297mm; /* A4 height */
    margin: 0; /* Remove default margins */
    padding: 20mm; /* Add padding for content */
    box-sizing: border-box; /* Include padding and border in element's total width and height */
  }

  h1, p {
    page-break-inside: avoid; /* Avoid breaking inside these elements */
  }
}

/* CSS for screen */
@media screen {
  .a4-page {
    width: 100%;
    max-width: 210mm;
    margin: auto;
    padding: 20mm;
    box-sizing: border-box;
  }
}

.titleHeader, .subHeader{
  margin-top: 20px ;
  background: #b8deff ;
  padding: 10px 16px;
  text-align: center;
}


.titleHeader h5,
.subHeader h5{
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}




.TeacherDashboard{
  padding: 40px 0px;
}

.TeacherDashboard .title{
  display: flex;
  justify-content: center;
  align-items: center;
}

.TeacherDashboard .title h1{
  margin-bottom: 0px;
  font-family: 'UniSans';
  margin-left: 8px;
}

.TeacherDashboard .Info{
  justify-content: center;
  margin-top: 30px;
}


.TeacherDashboard .Info .Left,
.TeacherDashboard .Info .Right{
  border: 2px solid #aad7ff; 
  border-radius: 10px;
  padding: 24px 24px 0px;
  position: relative;
}

.TeacherDashboard .Info .headline,
.TeacherDashboard .Info .headline,
.StudentEffortReport .card .headline,
.StudentResultReport .card .headline{
  position: absolute;
  font-size: 13px;
  background: linear-gradient(145deg, rgb(0, 127, 232), #2400FF);
  color: var(--White);
  border: none;
  top: -14px; /* Adjust to position the header above the card */
  left: 50%; /* Center the header horizontally */
  transform: translateX(-50%); /* Center the header horizontally */
  padding: 4px 12px; /* Padding for the header */
  border-radius: 8px; /* Make the header circular */
  width: max-content;
}


.TeacherDashboard .Info .card{
  border: none;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  flex-direction: row;
  padding:10px 20px;
 align-items: center;
 margin-bottom: 24px;
}

.TeacherDashboard .Info .card img{
  width: 40px;
  height: 40px;
}

.TeacherDashboard .Info .card h5{
  font-weight: 600;
  margin-bottom: 0px;
  color: #0055cc;
}

.TeacherDashboard .Info .card .body{
  padding: 0px 10px 0px 14px;
}


/* ---------------- Class Info ------------------ */

.TeacherDashboard .nav{
  width:max-content;
  border: none;
  border-radius: 8px;
  box-shadow: rgb(204, 219, 232) 2px 2px 6px 2px inset,
  rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

.TeacherDashboard .nav .nav-link{
  border-radius: 8px;
  padding: 5px 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}


.TeacherDashboard .nav .nav-link.active{
  background: linear-gradient(145deg, rgb(0, 127, 232), #2400FF);
  color:var(--White);
  border: none;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
} 




.StudentEffortReport .card,
.StudentResultReport .card{
  padding: 20px 0px;
  border: 2px solid #aad7ff;
  border-radius: 10px;
  position: relative;
}


/*  ---------------- Fixed header Responsive table CSS ---------------*/

.outer-wrapper {
  margin: 0px !important;
  padding: 0px !important;
  border-radius: 0px !important ;
  background: #ffffff !important;
  /* border: 1px solid rgb(224, 224, 224) !important;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15),
    inset 0 0 3px rgba(0, 0, 0, 0.2) !important; */
}

.icon {
  transition: 0.3s !important;
  color: var(--Primary) !important;
}

.icon:hover {
  transform: translateY(-4px) scale(1.2) !important;
  cursor: pointer !important;
}

.table-wrapper {
  /* overflow-y: scroll;
  overflow-x: scroll; */
  /* height: fit-content; */
  margin-top: 5px;
  padding-bottom: 0px;
  padding: 0px !important;
}

.table-wrapper::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.table-wrapper::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 0px;
}

.table-wrapper::-webkit-scrollbar-thumb {
  background-color: #93c0ff;
  border-radius: 6px;
  border: 1px solid transparent;
}

.outer-wrapper table {
  /* min-width: max-content; */
  border-collapse: separate;
  border-spacing: 0px;
  width: 100%;
}

.outer-wrapper table th {
  z-index: 1 !important;
  position: sticky;
  top: 0px;
  background: hsl(212, 100%, 88%);
  color: var(--Black);
  text-align: left;
  font-weight: 600;
  font-size: 15px;
  outline: 0.7px solid transparent;
  border: 1.5px solid transparent;
}

.outer-wrapper table th,
.outer-wrapper table td {
  padding: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.outer-wrapper table td {
  text-align: left;
  font-size: 14px;
  border: 0.5px solid rgba(137, 137, 137, 0.1);
  padding-left: 10px;
}


.PageNotFound {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PageNotFound h1 {
  font-weight: 600;
}

.lenevo .card1{
  border:2px solid rgb(168, 90, 0);
  background: rgba(255, 165, 62, 0.473);
  padding: 20px;
}

.lenevo .card2{
  border:2px solid rgba(0, 9, 128, 0.941);
  background: rgba(0, 48, 170, 0.2);
  padding: 20px;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
